import { Box, Button, ButtonGroup, Grid2, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { AnalysisResult } from "../lib/interfaces";
import { Download, Replay } from "@mui/icons-material";
import colors from "../lib/colors";
import { useNavigate } from 'react-router';

interface AnalysisDataDisplayProps {
    data: AnalysisResult,
    handleNewAnalysis: () => void
}

export function NotFoundText(){
    return <Typography color="DarkGray" fontStyle={"italic"}>Not Found/Not Applicable</Typography>
}

export function AnalysisDataDisplay({ data, handleNewAnalysis }: AnalysisDataDisplayProps) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"auto"} margin={theme.spacing(2)} borderRadius={theme.spacing(4)} bgcolor={"white"} padding={theme.spacing(4)} width={"85%"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginBottom={theme.spacing(4)} width={"100%"}>
                <Box flex={1} />
                <Typography flex={1} textAlign={"center"} variant="h4">Lease Analysis</Typography>
                <Box flex={1} display={"flex"} flexDirection={"row"} alignItems={"right"} justifyContent={"flex-end"}>
                    <Tooltip title="Download Form">
                        <IconButton
                            href={data.reportSASURL}
                            target="_blank"
                        >
                            <Download
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="New Analysis">
                        <IconButton onClick={handleNewAnalysis}>
                            <Replay
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Document Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.documentNumber ? <Typography variant="body1">{data.documentNumber}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Project Name:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.projectName ? <Typography variant="body1">{data.projectName}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Plot Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.plotNumber ? <Typography variant="body1">{data.plotNumber}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">District:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.district ? <Typography variant="body1">{data.district}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Village:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.village ? <Typography>{data.village}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Khewat:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.khewat ? <Typography variant="body1">{data.khewat}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Municipality:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.municipality ? <Typography variant="body1">{data.municipality}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registrar Office:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.registrarOffice ? <Typography variant="body1">{data.registrarOffice}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.agreementType ? <Typography variant="body1">{data.agreementType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.registrationDate ? <Typography variant="body1">{data.registrationDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.landlord ? <Typography variant="body1">{data.landlord}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.landlordRep ? <Typography variant="body1">{data.landlordRep}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative Role:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.landlordRepRole ? <Typography variant="body1">{data.landlordRepRole}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.tenant ? <Typography variant="body1">{data.tenant}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.tenantRep ? <Typography variant="body1">{data.tenantRep}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative Role:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.tenantRepRole ? <Typography variant="body1">{data.tenantRepRole}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.unit ? <Typography variant="body1">{data.unit}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.floor ? <Typography variant="body1">{data.floor}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Wing:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.wing ? <Typography variant="body1">{data.wing}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Other Unit Info:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.addressExtraDiscriminator ? <Typography variant="body1">{data.addressExtraDiscriminator}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Chargeable Area Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.chargeableAreaType ? <Typography variant="body1">{data.chargeableAreaType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Super Builtup Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.superBuiltupArea ? <Typography variant="body1">{data.superBuiltupArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">BuiltUp Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.builtUpArea ? <Typography variant="body1">{data.builtUpArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Carpet Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.carpetArea ? <Typography variant="body1">{data.carpetArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Start Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.agreementStartDate ? <Typography variant="body1">{data.agreementStartDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Expiration Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.agreementExpirationDate ? <Typography variant="body1">{data.agreementExpirationDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Duration:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.agreementDuration ? <Typography variant="body1">{data.agreementDuration}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Rent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.rentMonthly ? <Typography variant="body1">{data.rentMonthly}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Square Foot Rent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.rentPerSquareFootMonthly ? <Typography variant="body1">{data.rentPerSquareFootMonthly}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Consideration Value:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.considerationValue ? <Typography variant="body1">{data.considerationValue}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Escalation Terms:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.escalationTerms ? <Typography variant="body1">{data.escalationTerms}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Condition:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.unitCondition ? <Typography variant="body1">{data.unitCondition}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit With Fit Outs:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.unitWithFitOuts ? <Typography variant="body1">{data.unitWithFitOuts}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Furnished Rate:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.furnishedRate ? <Typography variant="body1">{data.furnishedRate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Rent Free Period:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.rentFreePeriod ? <Typography variant="body1">{data.rentFreePeriod}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Lockin:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.landlordLockIn ? <Typography variant="body1">{data.landlordLockIn}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Lockin:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.tenantLockIn ? <Typography variant="body1">{data.tenantLockIn}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.securityDeposit ? <Typography variant="body1">{data.securityDeposit}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit Equivalent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.securityDepositEquivalent ? <Typography variant="body1">{data.securityDepositEquivalent}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Market Value:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.marketValue ? <Typography variant="body1">{data.marketValue}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Stamp Duty Amount:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.stampDutyAmount ? <Typography variant="body1">{data.stampDutyAmount}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Amount:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.registrationAmount ? <Typography variant="body1">{data.registrationAmount}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Slots:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.carParkingSlots ? <Typography variant="body1">{data.carParkingSlots}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.carParkingType ? <Typography variant="body1">{data.carParkingType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Additional Car Parking Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.carParkingCharge ? <Typography variant="body1">{data.carParkingCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Slots:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.twoWheelerSlots ? <Typography variant="body1">{data.twoWheelerSlots}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.twoWheelerCharge ? <Typography variant="body1">{data.twoWheelerCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.monthlyCamCharge ? <Typography variant="body1">{data.monthlyCamCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.camCharge ? <Typography variant="body1">{data.camCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Paid By:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.camPaidBy ? <Typography variant="body1">{data.camPaidBy}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Total Property Tax:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.totalPropertyTax ? <Typography variant="body1">{data.totalPropertyTax}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.propertyTax ? <Typography variant="body1">{data.propertyTax}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax Paid By:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.propertyTaxPaidBy ? <Typography variant="body1">{data.propertyTaxPaidBy}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Plan:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    {data.floorPlan ? <Typography variant="body1">{data.floorPlan}</Typography> : <NotFoundText />}
                </Grid2>
            </Grid2>
        </Box>
    );
}