import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { PropayaAppBar } from "./PropayaAppBar";
import { CloudUploadOutlined, CloudUpload } from "@mui/icons-material";
import { VisuallyHiddenInput } from "../lib/utils";
import { AnalysisDataDisplay } from "./AnalysisDataDisplay";
import { useCallback, useState } from "react";
import { AnalysisResult } from "../lib/interfaces";
import { BlockBlobClient } from "@azure/storage-blob";
import colors from "../lib/colors";
import { useDropzone } from "react-dropzone";
import { getAuth } from "firebase/auth";
import { ORIGIN } from "../App";

enum View {
    NotUploaded,
    Uploading,
    Processing,
    Preview
}

export function LeaseAnalysisView() {
    const auth = getAuth();
    const theme = useTheme();
    const [selectedFile, setSelectedFile] = useState<File>(new File([], "No file selected"));
    const [data, setData] = useState<AnalysisResult>();
    const [view, setView] = useState<View>(View.NotUploaded);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    async function FetchData(org: string, blobName: string) {
        const requestBody = {
            "organization": org,
            "blobName": blobName
        };
        const idToken = await auth.currentUser?.getIdToken();
        const response = await fetch(ORIGIN + "/analyze-propstack", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            // credentials: "include",
            body: JSON.stringify(requestBody)
        });
        response.json().then((result) => {
            setData(result);
        })
        .catch((error) => {
            alert("Failed to fetch data");
            setView(View.NotUploaded);
        });
    }

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || event.target.files.length === 0) return;
        FileChosen(event.target.files[0])
    }

    async function UploadFile(file: File) {
        if (auth.currentUser === null) return "";
        const response = await fetch(`https://leaseanalysisfunctions.azurewebsites.net/api/fetch_demo_sas?blobName=${auth.currentUser.uid}/${encodeURIComponent(file.name)}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
        });
        const SAS = await response.json();
        const blockBlobClient = new BlockBlobClient(SAS);
        try {
            await blockBlobClient.uploadData(file);
            return blockBlobClient.name;
        }
        catch {
            return "";
        }
    }

    async function FileChosen(file: File) {
        if (auth.currentUser === null) return;
        setView(View.Uploading)
        setSelectedFile(file);
        const blobName = await UploadFile(file);
        if (blobName === "") return alert("Upload failed!");
        setView(View.Processing)
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
        FetchData("tmp", blobName);
        // FetchData("tmp", `${auth.currentUser.uid}/${file.name}`);
        setView(View.Preview);
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
        FileChosen(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': [".pdf"],
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            "image/heic": []
        },
        // disabled: (uploadState != UploadState.NotUploaded)
    });

    function handleNewAnalysis() {
        setView(View.NotUploaded);
        setData(undefined);
        setPreviewUrl(null);
        setSelectedFile(new File([], "No file selected"));
    }

    return (
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} height={"100vh"} width={"100%"} bgcolor={colors.SchemesSurfaceContainerLow}>
            {
                view === View.NotUploaded &&
                <Box
                    {...getRootProps()}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent={"center"}
                    gap={theme.spacing(2)}
                    margin={"auto"}
                    sx={{
                        background: 'white',
                        padding: theme.spacing(4),
                        borderRadius: theme.spacing(4),
                        width: "50vw",
                        height: "40vh"
                    }}
                >
                    <CloudUploadOutlined color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography>Drop your file here or</Typography>
                    <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                    <Button component="label" variant="contained" color="secondary">
                        Browse
                    </Button>
                    <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
                </Box>
            }
            {
                view === View.Uploading &&
                <Box margin="auto" display={"flex"} flexDirection={"column"} alignItems={"center"} bgcolor={"white"} borderRadius={theme.spacing(4)} padding={theme.spacing(16)}>
                    <Typography variant='h3' marginBottom={theme.spacing(4)}>Uploading...</Typography>
                    <CircularProgress />
                </Box>
            }
            {
                (view === View.Preview || view === View.Processing) &&
                <Box display={"flex"} flexDirection={"row"} height={"100%"} width={"100vw"} sx={{ marginLeft: theme.spacing(8) }}>
                    <Box margin={theme.spacing(2)}
                        borderRadius={theme.spacing(4)}
                        bgcolor={"white"}
                        padding={theme.spacing(4)}
                        width={"50%"}
                        sx={{
                            boxSizing: 'border-box',
                        }}>
                        {previewUrl ? (
                            <iframe
                                src={previewUrl}
                                width="100%"
                                height="100%"
                                title="PDF Preview"

                            />
                        ) : (
                            <Typography>Loading preview...</Typography>
                        )}
                    </Box>
                    <Box flex={1} overflow={"auto"} >
                        {
                            view === View.Processing || data === undefined ?
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"} >
                                    <Typography variant='h3' marginBottom={theme.spacing(4)}>Processing...</Typography>
                                    <CircularProgress />
                                </Box>
                                :
                                <AnalysisDataDisplay data={data} handleNewAnalysis={handleNewAnalysis} />
                        }
                    </Box>
                </Box>
            }
        </Box>
    );
}