import * as React from 'react';
import Box from '@mui/material/Box';
import {Button, Card, Checkbox, Grid2, FormLabel, FormControlLabel, OutlinedInput, Typography, useTheme} from '@mui/material';
import {Sidebar} from './Sidebar';
import { styled } from '@mui/system';

{/*Styling of form layout by MUI template can also be adjusted if needed*/}
const FormGrid = styled(Grid2)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export function AccountSettings() {
    const theme = useTheme();
    return (
        /*Ideally this box component would have the hex value that sign in has, but there was difficulty in stacking to get the color to showcase the entire screen*/
        <Box component="main" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: theme.spacing(6), width: '100vw' }}>
            <Box sx={{ marginTop: theme.spacing(4) }}>
                <Typography variant='h3'>Account Information</Typography>
                <Card variant='outlined' sx={{ width: '100vw' }}>
                    <Grid2 size={12}>
                        <FormGrid sx={{ marginBottom: theme.spacing(2) }}>
                            <FormLabel htmlFor="first-name" required>
                                First name
                            </FormLabel>
                            <OutlinedInput
                                id="first-name"
                                name="first-name"
                                type="name"
                                placeholder="John"
                                autoComplete="first name"
                                required
                                size="small"
                            />
                        </FormGrid>
                        <FormGrid sx={{ marginBottom: theme.spacing(2) }}>
                            <FormLabel htmlFor="last-name" required>
                                Last name
                            </FormLabel>
                            <OutlinedInput
                                id="last-name"
                                name="last-name"
                                type="last-name"
                                placeholder="Snow"
                                autoComplete="last name"
                                required
                                size="small"
                            />
                        </FormGrid>
                        <FormGrid sx={{ marginBottom: theme.spacing(2) }}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <OutlinedInput
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                required
                                size="small"
                            />
                        </FormGrid>
                        <FormGrid sx={{ marginBottom: theme.spacing(2) }}>
                            <FormLabel htmlFor="organization">Organization</FormLabel>
                            <OutlinedInput
                                id="organization"
                                name="organization"
                                type="organization"
                                placeholder="Organization Name"
                                autoComplete="Organization-name"
                                required
                                size="small"
                            />
                        </FormGrid>
                    </Grid2>
                </Card>
            </Box>
        </Box>
    );
}
