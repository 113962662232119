import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import propstackIcon from '../images/propstackminilogo.png';
import propstackLogo from "../images/propstacklogo.png"; 
import { useMsal } from "@azure/msal-react";

interface NavDrawerHeaderProps {
    navDrawerCollapsed: boolean
}

export function NavDrawerHeader({navDrawerCollapsed}: NavDrawerHeaderProps){
    const theme = useTheme();
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const user = accounts[0];
    
    return (
        <Box gap={theme.spacing(2)} display='inline-flex' flexDirection="column"  width="100%" alignItems="center">
            <Box gap={theme.spacing(1.5)} display='inline-flex' paddingLeft={theme.spacing(3)} paddingRight={theme.spacing(3)}>
                <Box justifyContent="center">
                    {
                        navDrawerCollapsed ? 
                        <Box onClick={() => navigate("/")} component="img" src={propstackIcon} sx={{height: "48px", cursor: "pointer", position: "relative", right: "2px" }} />
                        :
                        <Box onClick={() => navigate("/")} component="img" src={propstackLogo} sx={{height: "48px", cursor: "pointer"}} />
                    }
                </Box>
                {/* <Box>
                    <Typography fontSize={16} fontWeight={500}>{user.name}</Typography>
                    <Typography fontSize={12} fontWeight={400} color="gray">{user.username}</Typography>
                </Box> */}
            </Box>
            <Box alignSelf="stretch">
                <Divider variant="middle"/>
            </Box>
        </Box>
    );
}