import { AnalysisResult } from "./interfaces"
import { AreaType, CarParkingType, LeaseType, LiabilityTypeResult, UnitCondition, YesNo } from "../lib/enums";

export const data: AnalysisResult = {
    "documentNumber": "25249",
    "projectName": "Altimus",
    "plotNumber": "130",
    "district": "Mumbai",
    "village": "Worli",
    "khewat": null,
    "municipality": null,
    "registrarOffice": "Mumbai City 2",
    "agreementType": LeaseType.LEAVE_AND_LICENSE,
    "registrationDate": "10-24-2024",
    "landlord": "Whispering Heights Real Estate Private Limited",
    "landlordRep": "Devashish Gupta",
    "landlordRepRole": "Authorized Signatory",
    "tenant": "KKR India Advisors Private Limited",
    "tenantRep": "Farida Karanjia",
    "tenantRepRole": "Authorized Signatory",
    "unit": "4101",
    "floor": "41",
    "wing": null,
    "addressExtraDiscriminator": null,
    "superBuiltupArea": null,
    "chargeableAreaType": AreaType.BUILTUP,
    "builtUpArea": 42700,
    "carpetArea": 25620,
    "agreementStartDate": "10-01-2024",
    "agreementExpirationDate": null,
    "agreementDuration": 120,
    "rentMonthly": 14945000,
    "rentPerSquareFootMonthly": 350,
    "considerationValue": 14945000,
    "escalationTerms": null,
    "unitCondition": UnitCondition.WARMSHELL,
    "unitWithFitOuts": YesNo.YES,
    "furnishedRate": YesNo.NO,
    "rentFreePeriod": 6,
    "landlordLockIn": 120,
    "tenantLockIn": 60,
    "securityDeposit": 179340000,
    "securityDepositEquivalent": 12,
    "marketValue": null,
    "stampDutyAmount": 17767000,
    "registrationAmount": 30000,
    "carParkingSlots": 32,
    "carParkingType": CarParkingType.COVERED,
    "carParkingCharge": null,
    "twoWheelerSlots": null,
    "twoWheelerCharge": null,
    "monthlyCamCharge": 1096700,
    "camCharge": 25,
    "camPaidBy": LiabilityTypeResult.TENANT,
    "totalPropertyTax": null,
    "propertyTax": null,
    "propertyTaxPaidBy": LiabilityTypeResult.TENANT,
    "floorPlan": null,
    "reportSASURL": "string"
}

export const emptyResult: AnalysisResult = {
    "documentNumber": "",
    "projectName": "",
    "plotNumber": "",
    "district": "",
    "village": "",
    "khewat": "",
    "municipality": "",
    "registrarOffice": "",
    "agreementType": null,
    "registrationDate": "",
    "landlord": "",
    "landlordRep": "",
    "landlordRepRole": "",
    "tenant": "",
    "tenantRep": "",
    "tenantRepRole": "",
    "unit": "",
    "floor": "",
    "wing": "",
    "addressExtraDiscriminator": "",
    "chargeableAreaType": null,
    "superBuiltupArea": null,
    "builtUpArea": null,
    "carpetArea": null,
    "agreementStartDate": "",
    "agreementExpirationDate": "",
    "agreementDuration": null,
    "rentMonthly": null,
    "rentPerSquareFootMonthly": null,
    "considerationValue": null,
    "escalationTerms": "",
    "unitCondition": null,
    "unitWithFitOuts": null,
    "furnishedRate": null,
    "rentFreePeriod": null,
    "landlordLockIn": null,
    "tenantLockIn": null,
    "securityDeposit": null,
    "securityDepositEquivalent": null,
    "marketValue": null,
    "stampDutyAmount": null,
    "registrationAmount": null,
    "carParkingSlots": null,
    "carParkingType": null,
    "carParkingCharge": null,
    "twoWheelerSlots": null,
    "twoWheelerCharge": null,
    "monthlyCamCharge": null,
    "camCharge": null,
    "camPaidBy": null,
    "totalPropertyTax": null,
    "propertyTax": null,
    "propertyTaxPaidBy": null,
    "floorPlan": null,
    "reportSASURL": ""
}