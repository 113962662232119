import { Box, Typography } from "@mui/material";
import HouseGif from "../images/loading.gif";

export function Loading(){
    return (
        <Box sx={{margin: "15 auto", textAlign: "center"}}>
            <Box component="img" src={HouseGif} sx={{marginTop: "10vh"}} />
            <Typography variant="h5" color="GrayText">Loading...</Typography>
        </Box>
    );
}