import { AuthErrorCodes, browserPopupRedirectResolver, createUserWithEmailAndPassword, getAdditionalUserInfo, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, updateCurrentUser, updateProfile } from "firebase/auth";
import { Box, Button, Card, Grid2, TextField, Typography, useTheme } from "@mui/material";
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router";
import { Google, ViewHeadline } from "@mui/icons-material";
import propayaLogo from '../images/Propaya_Logo.png';
import { useContext, useState } from "react";
import { BackendAPI } from "../lib/backendAPI";
import loginPhoto from '../images/invite.svg';
import { InviteContext, UserContext } from "../App";

export function SignIn() {
    const inviteContext = useContext(InviteContext);
    const userContext = useContext(UserContext);
    const auth = getAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const [firstNameField, setFirstNameField] = useState("");
    const [lastNameField, setLastNameField] = useState("");
    const [emailField, setEmailField] = useState(inviteContext.inviteVerification?.email ?? "");
    const [signInPasswordField, setSignInPasswordField] = useState("");
    const [createAccountPasswordField, setCreateAccountPasswordField] = useState("");
    const [createAccountPasswordConfirmationField, setCreateAccountPasswordConfirmationField] = useState("");
    const [isCreatingAccount, setIsCreatingAccount] = useState( inviteContext.inviteVerification !== null && !inviteContext.inviteVerification.userExists );
    const [errorText, setErrorText] = useState("");
    const [submitError, setSubmitError] = useState(false);
    const lockEmailField = inviteContext.inviteVerification !== null;

    function handleGoogleSignIn() {
        const googleProvider = new GoogleAuthProvider();
        signInWithPopup(auth, googleProvider, browserPopupRedirectResolver).then((userCredential) => {
            console.log(getAdditionalUserInfo(userCredential))
            const additionalUserInfo = getAdditionalUserInfo(userCredential);
            if (additionalUserInfo !== null && additionalUserInfo.isNewUser) {
                // create new user in db
                return BackendAPI.CreateUserFromUserCredential(userCredential);
            }
        }).then(() => {
            navigate("/");
        }).catch((reason) => {
            alert("Login Failed");
        });
    }

    // if user doesn't exist, show create account fields
    function handlePasswordSignIn() {
        signInWithEmailAndPassword(auth, emailField, signInPasswordField).then((userCredential) => {
            setSubmitError(false);
            setErrorText("");
            if (inviteContext.inviteVerification !== null && inviteContext.inviteId !== null) {
                return BackendAPI.AcceptInvite(inviteContext.inviteId, userCredential.user)
                .then(() => {
                    BackendAPI.GetUser(userCredential.user).then((user) => userContext.setUser(user));
                });
            }
        })
        .then(() => {
            navigate("/");
        })
        .catch((error) => {
            console.log(error);
            if (error.code === AuthErrorCodes.INVALID_IDP_RESPONSE) { // user not found i.e. new user
                setErrorText("Incorrect Email or Password");
                setSubmitError(true);
            }
        });
    }

    function handlePasswordCreateAccount() {
        if (createAccountPasswordField !== createAccountPasswordConfirmationField) {
            setErrorText("Passwords do not match.");
            setSubmitError(true);
            return; //error, passwords don't match
        }
        createUserWithEmailAndPassword(auth, emailField, createAccountPasswordField).then((userCredential) => {
            setErrorText("");
            setSubmitError(false);
            updateProfile(userCredential.user, { displayName: (firstNameField + " " + lastNameField) })
            return BackendAPI.CreateUserFromUserCredential(userCredential).then(() => {
                if (inviteContext.inviteVerification !== null && inviteContext.inviteId !== null) {
                    return BackendAPI.AcceptInvite(inviteContext.inviteId, userCredential.user);
                }
            }).then(() => {
                BackendAPI.GetUser(userCredential.user).then((user) => userContext.setUser(user));
            });
        })
        .then(() => {
            navigate("/");
        }).catch((error) => {
            console.log(error);
            console.log(error.code);
            setErrorText("Password must contain an uppercase letter, a special character, and be at least 6 characters.");
            setSubmitError(true);
        });
    }

    return (
        //background box
        <Box width={'100vw'} height={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} sx={{
            backgroundColor: "#8E68EC",
            margin: '0 auto', position: 'relative'
        }}>

            {/*Inner white box container*/}
            <Box
                width={'80vw'}
                height={"80vh"}
                display={"flex"}
                sx={{ borderRadius: '48px', backgroundColor: 'white' }}
            >
                {/*Left half*/}
                <Box
                    width={"50%"}
                    height={"100%"}
                    sx={{
                        borderRadius: '48px',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                    }}>
                    {/*Inner content of left half*/}
                    <Box
                        sx={{
                            alignItems: 'center',
                        }}
                        margin={theme.spacing(4)}
                    >
                        <Box component="img" src={propayaLogo} alt="Logo" sx={{ width: "15vw" }} />
                        <Typography
                            variant="h3"
                            marginTop={theme.spacing(2)}
                            marginBottom={theme.spacing(2)}
                            sx={{
                                textAlign: 'left'
                            }}
                        >
                            {!isCreatingAccount ? "Welcome Back" : "Create an Account"}
                        </Typography>
                        {!isCreatingAccount ?
                            <Box width={"80%"}>
                                <Typography variant="h6">Email</Typography>
                                <TextField
                                    disabled={lockEmailField}
                                    required
                                    fullWidth
                                    label="Email"
                                    autoComplete="username"
                                    value={emailField}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmailField(event.target.value)
                                    }}
                                    error={submitError}
                                />
                                <Typography variant="h6">Password</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={signInPasswordField}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSignInPasswordField(event.target.value)
                                    }}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                        if (event.key === "Enter") handlePasswordSignIn();
                                    }}
                                    error={submitError}
                                    helperText={errorText}
                                />
                            </Box>
                            :
                            <Box width={"80%"}>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <TextField
                                            disabled={lockEmailField}
                                            required
                                            fullWidth
                                            label="Email"
                                            autoComplete="username"
                                            value={emailField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setEmailField(event.target.value)
                                            }}
                                            error={submitError}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            fullWidth
                                            label="First Name"
                                            autoComplete="given-name"
                                            value={firstNameField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setFirstNameField(event.target.value)
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            fullWidth
                                            label="Last Name"
                                            autoComplete="family-name"
                                            value={lastNameField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setLastNameField(event.target.value)
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            autoComplete="new-password"
                                            value={createAccountPasswordField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCreateAccountPasswordField(event.target.value)
                                            }}
                                            error={submitError}
                                        />
                                    </Grid2>
                                    <Grid2 sx={{ marginBottom: theme.spacing(2) }} size={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Confirm Password"
                                            type="password"
                                            autoComplete="new-password"
                                            value={createAccountPasswordConfirmationField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCreateAccountPasswordConfirmationField(event.target.value)
                                            }}
                                            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                                if (event.key === "Enter") handlePasswordCreateAccount();
                                            }}
                                            error={submitError}
                                            helperText={errorText}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Box>
                        }
                        <Button variant="contained" onClick={isCreatingAccount ? handlePasswordCreateAccount : handlePasswordSignIn} sx={{ display: 'flex', flexDirection: 'row', maxWidth: '250px', marginTop: theme.spacing(2) }}>{isCreatingAccount ? "Create Account" : "Login"}</Button>
                        {/* <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', gap: theme.spacing(2),
                            width: { xs: '100%', sm: '80%', md: '60%', lg: '80%' },
                        }}>
                            <Divider sx={{ width: '50%' }}>or</Divider>
                            <Button sx={{ gap: 2, width: '250px' }} variant="outlined" startIcon={<Google />} id="signinwithgoogleid" onClick={handleGoogleSignIn}>
                                Sign in with Google
                            </Button>
                        </Box> */}
                        { inviteContext.inviteVerification === null && <Box sx={{ display: "flex", alignItems: "center", marginTop: theme.spacing(4) }}>
                            <Typography variant="body2" sx={{ marginRight: "8px", fontWeight: 'bold' }}>
                                {/*This toggle the text depending on which action the user is taking*/}
                                {isCreatingAccount ? "Have an account?" : "Don't have an account?"}
                            </Typography>
                            <Typography color={"#5388F2"} sx={{ cursor: "pointer" }} onClick={() => {
                                {/*Currently the email isn't cleared for the ease of the user but that can be adjusted*/ }
                                setIsCreatingAccount(!isCreatingAccount);
                                if (isCreatingAccount) {
                                    setSignInPasswordField("");
                                }
                                else {
                                    setCreateAccountPasswordField("");
                                    setCreateAccountPasswordConfirmationField("");
                                    setFirstNameField("");
                                    setLastNameField("");
                                }
                                setErrorText("");
                                setSubmitError(false);
                            }}
                            >
                                {isCreatingAccount ? "Sign in" : "Sign Up"}
                            </Typography>
                        </Box>}
                    </Box>
                </Box>
                {/*Right half with img*/}
                <Box
                    width={"50%"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                        borderRadius: '0px 48px 48px 0px',
                        backgroundColor: '#E9E0FF',
                        overflow: 'hidden'
                    }}
                >
                    <img
                        src={loginPhoto}
                        alt="Propaya Login Image"
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover'
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}