import { Box } from "@mui/material";
import { Outlet } from "react-router";
import { Sidebar } from "./Sidebar";
import colors from "../lib/colors";
import { createContext, useState } from "react";

export const COLLAPSED_WIDTH = 104;
export const EXPANDED_WIDTH = 320;

export const SidebarWidthContext = createContext<number>(window.innerWidth < 1442 ? COLLAPSED_WIDTH : EXPANDED_WIDTH);

export function Page() {
    const [navDrawerCollapsed, setNavDrawerCollapsed] = useState(window.innerWidth < 1442);
    const [navDrawerWidth, setNavDrawerWidth] = useState(window.innerWidth < 1442 ? COLLAPSED_WIDTH : EXPANDED_WIDTH);
    
    return (
        <Box height={"100vh"} width={"100vw"} overflow={"clip"} display={"flex"} flexDirection={"row"} >
            <Sidebar navDrawerCollapsed={navDrawerCollapsed} navDrawerWidth={navDrawerWidth} setNavDrawerCollapsed={setNavDrawerCollapsed} setNavDrawerWidth={setNavDrawerWidth} />
            <Box width={`calc(100vw - ${navDrawerWidth}px)`} height={"100vh"} sx={{ background: colors.SchemesSurfaceContainerLow, overflowX: "clip"}} overflow={"auto"} >
                <SidebarWidthContext.Provider value={navDrawerWidth}>
                    <Outlet />
                </SidebarWidthContext.Provider>
            </Box>
        </Box>
    );
}