import { Box, Button, Card, CircularProgress, Grid2, IconButton, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { PropayaUser } from "../lib/interfaces";
import { ORIGIN } from "../App";
import { ORG_USERS } from "../lib/apiPaths";
import { getAuth } from "firebase/auth";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Add, Delete } from "@mui/icons-material";
import { modalStyle } from "../lib/styles";
import { UserRole, UserStatus } from "../lib/enums";
import { BackendAPI } from "../lib/backendAPI";
import { Sidebar } from "./Sidebar";
import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import colors from '../lib/colors';

export function Documents() {
    interface Row {
        id: number;
        uploader: string;
        organization: string;
        date: string;
        form: string;
    }

    const columns: GridColDef[] = [
        { field: 'uploader', headerName: 'Uploader', width: 150 },
        { field: 'organization', headerName: 'Organization', width: 150 },
        { field: 'date', headerName: 'Date', width: 90 },
        { field: 'form', headerName: 'Form', width: 90 },
    ];

    /*The actual information from the row will need to be sourced from the user data*/
    /*Also allowing the user to view the previous form could be useful which would be through view*/
    const rows: Row[] = [
        { id: 1, uploader: 'John Doe', organization: 'Propaya', date: '1/5/25', form: 'View' }
    ];
    const theme = useTheme();
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: colors.SchemesSurfaceContainerLow,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                width: '100%',
                minHeight: '100vh'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    marginTop: theme.spacing(4),
                }}
            >
                <Grid2 size={12} sx={{ marginBottom: theme.spacing(4) }}>
                    <Card variant='outlined' sx={{ width: '100vw' }}>
                        <Typography variant="h3">Documents</Typography>
                        <Typography variant="h6">Document Upload History</Typography>
                        {/*Dummy data*/}
                        <DataGrid
                            rows={rows}
                            columns={columns}
                        />
                    </Card>
                </Grid2>
            </Box>

        </Box>
    );

};