export enum UserRole {
    ADMIN = "admin",
    USER = "user"
}

export enum UserStatus {
    ACTIVE = "active",
    INACTIVE = "inactive"
}

export enum LeaseType {
    LEASE = "Lease",
    LEAVE_AND_LICENSE = "Leave & License",
    SALE = "Sale",
    CONVEYANCE = "Conveyance"
}

export enum AreaType {
    CARPET = "Carpet Area",
    BUILTUP = "Builtup Area",
    SUPER_BUILTUP = "Super Builtup Area"
}

export enum UnitCondition {
    FURNISHED = "Furnished",
    BARESHELL = "Bareshell",
    WARMSHELL = "Warmshell"
}

export enum YesNo {
    YES = "Yes",
    NO = "No"
}

export enum CarParkingType {
    COVERED = "Covered",
    UNCOVERED = "Uncovered",
    BOTH = "Both"
}

export enum LiabilityTypeResult {
    TENANT = "Tenant/Licensee",
    LANDLORD = "Landlord/Licensor",
    BOTH = "Both"
}