import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { AccountCircle, ArrowBack, ArrowLeft, ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from "./Page";
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react';
import propayaLogo from '../images/Propaya_Logo.png';
import propayaIcon from '../images/Propaya_icon.png';

interface NavDrawerFooterProps {
    navDrawerCollapsed: boolean
    setNavDrawerCollapsed: (collapsed: boolean) => void
    setNavDrawerWidth: (width: number) => void
}

export function NavDrawerFooter({ navDrawerCollapsed, setNavDrawerCollapsed, setNavDrawerWidth }: NavDrawerFooterProps) {
    const theme = useTheme();
    const { instance } = useMsal();
    let currentPath: string = useLocation().pathname.split("/")[1];



    async function OnClickLogout() {
        instance.logoutRedirect();
    }

    return (
        <Box marginTop="auto" marginBottom={0} display='inline-flex' flexDirection="column" width="100%" >

            <Box alignSelf="stretch">
                <Divider variant="middle" />
            </Box>
            <List>
                <ListItem >
                    <ListItemButton component={Link} to={"/login"}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText slotProps={{primary: {
                                sx: {
                                    fontWeight: currentPath == "Login" ? "bold" : "regular",
                                    fontSize: 18
                                }
                            }}}
                                primary="Sign Out" />
                        }
                    </ListItemButton>
                </ListItem >
                <ListItem >
                    <ListItemButton onClick={() => {
                        setNavDrawerCollapsed(!navDrawerCollapsed)
                        if (navDrawerCollapsed) {
                            setNavDrawerWidth(EXPANDED_WIDTH);
                        }
                        else {
                            setNavDrawerWidth(COLLAPSED_WIDTH);
                        }
                    }}>
                        <ListItemIcon>
                            {
                                navDrawerCollapsed ?
                                    <ChevronRight />
                                    :
                                    <ChevronLeft />
                            }
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText slotProps={{primary: {
                                sx: {
                                    fontSize: 18
                                }
                            }}}
                                primary="Collapse" />
                        }
                    </ListItemButton>
                </ListItem>
            </List>
            <Box
                display='flex' 
                justifyContent='center'
                alignItems='center'  
                padding={theme.spacing(2)}
            >
                {navDrawerCollapsed ?
                    <Box
                        component="img"
                        src={propayaIcon}
                        sx={{
                            height: "40px",
                            margin: 'auto'
                        }}
                    />
                    :
                    <Box
                        component="img"
                        src={propayaLogo}
                        sx={{
                            height: "40px",
                            margin: 'auto'
                        }}
                    />
                }
            </Box>
        </Box>
    );
}