import { Box, Button, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import propayaLogo from '../images/Propaya_Logo.png';
import { InviteContext, ORIGIN } from "../App";
import { VERIFY_INVITE } from "../lib/apiPaths";
import { InviteVerification } from "../lib/interfaces";
import { Loading } from "./Loading";
import acceptPhoto from '../images/property_illustration.svg';

export function AcceptInvite() {
    const inviteContext = useContext(InviteContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const inviteId = searchParams.get("t") ?? "";
    const [inviteValid, setInviteValid] = useState<boolean | null>(null);
    const [inviteVerification, setInviteVerification] = useState<InviteVerification>({ email: "", userExists: false, organization: "" });
    const [loading, setLoading] = useState<boolean>(true);

    async function VerifyInvite() {
        if (inviteId === "") {
            setInviteValid(false);
            return;
        }
        fetch(ORIGIN + VERIFY_INVITE + `?t=${inviteId}`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
        }).then((response) => {
            setLoading(false);
            if (!response.ok) {
                setInviteValid(false);
                return Promise.reject("Invite Invalid")
            }
            setInviteValid(true);
            return response.json();
        }).then((verifyInviteResponse: InviteVerification) => {
            setInviteVerification(verifyInviteResponse);
        }).catch((error) => {
            setLoading(false);

        });
    }

    function AcceptInvite() {
        inviteContext.setInviteVerification(inviteVerification);
        inviteContext.setInviteId(inviteId);
        navigate("/login");
    }

    useEffect(() => {
        //verify invite
        VerifyInvite();
    }, []);

    return (
        loading ? <Loading /> :
            <Box width={'100vw'} height={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} sx={{
                backgroundColor: "#8E68EC",
                margin: '0 auto', position: 'relative'
            }}>
                <Box
                    width={'70vw'}
                    height={'90vh'}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ borderRadius: '48px', backgroundColor: 'white', margin: 'auto' }}
                >
                    <Box
                        component="img"
                        src={propayaLogo}
                        alt="Propaya Logo"
                        sx={{
                            objectFit: 'contain',
                            height: 'auto',
                            width: "25vw"
                        }}
                    />
                    <Box
                        component="img"
                        src={acceptPhoto}
                        alt="Accept Invite Photo"
                        sx={{
                            objectFit: 'contain',
                            height: 'auto',
                            width: "25vw"

                        }}
                    />
                    {
                        inviteValid ?
                        <>
                            <Typography sx={{
                                textAlign: 'center',
                                margin: theme.spacing(2),
                                fontSize: '1.5rem'
                            }}>
                                <strong>{inviteVerification.organization}</strong> has invited <em>{inviteVerification.email}</em> to join their organization.
                            </Typography>
                            <Button variant="contained" color="success" onClick={AcceptInvite} sx={{ display: 'flex', flexDirection: 'row', maxWidth: '250px' }}>Accept Invite</Button>
                        </>
                        :
                        <Typography sx={{
                            textAlign: 'center',
                            margin: theme.spacing(2),
                            fontSize: '1.5rem'
                        }}>
                            Sorry! Invalid Invite.
                        </Typography>
                    }
                </Box>
            </Box>
    );
}