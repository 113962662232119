import { Box, Button, createTheme, ThemeProvider, Typography, Card } from '@mui/material';
import colors from './lib/colors';
import { createContext, useEffect, useState } from 'react';
import { SignIn } from './components/SignIn';
import { getAuth } from 'firebase/auth';
import { Route, Routes, BrowserRouter, useNavigate } from 'react-router';
import { LeaseAnalysisView } from './components/LeaseAnalysisView';
import { Page } from './components/Page';
import { AuthValidator } from './AuthValidator';
import { AcceptInvite } from './components/AcceptInvite';
import { ManageOrganization } from './components/ManageOrganization';
import { Documents } from './components/Documents';
import { AccountSettings } from './components/AccountSettings';
import { BackendAPI } from './lib/backendAPI';
import { MobileValidator } from './MobileValidator';
import { InviteVerification, PropayaUser, UserContextType } from './lib/interfaces';
import { InviteContextType } from './lib/interfaces';
import { UserRole } from './lib/enums';
import { LeaseAnalysisQAView } from './components/LeaseAnalysisQAView';
import { Loading } from './components/Loading';

// const analytics = getAnalytics(firebaseApp);

let originURL: string | undefined = process.env.REACT_APP_API_ORIGIN_URL;
if (originURL === undefined) {
  // originURL = "https://b2b-backend-heazg5a3f4ewa9g0.westus-01.azurewebsites.net
  originURL = "https://b2b-backend-dev-g2g8bcaqfcepcpgs.westus-01.azurewebsites.net"
  // originURL = "http://127.0.0.1:8000"
}

export const ORIGIN: string = originURL;

export const InviteContext = createContext<InviteContextType>({
  inviteVerification: null,
  setInviteVerification: () => { },
  inviteId: null,
  setInviteId: () => { }
});

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => { }
});

function App() {
  const auth = getAuth();
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none"
      },
      fontFamily: "Roboto, Arial",
      allVariants: {
        color: colors.greyDarker
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            // "&:hover": {
            //   backgroundColor: colors.violetLighter,
            // },
          }
        },
        defaultProps: {
          disableElevation: true
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            "&:hover": {
              backgroundColor: colors.violetLighter,
            },
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: theme.spacing(4),
            borderRadius: '44px',
            gap: theme.spacing(1),
            background: 'white',
            [theme.breakpoints.up('sm')]: {
              maxWidth: '1080px',
            },
            boxShadow:
              'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
            ...theme.applyStyles('dark', {
              boxShadow:
                'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
            })
          }),

        }
      }
    },
    palette: {
      primary: {
        main: colors.violetDark,
      },
      secondary: {
        main: colors.violetLightest,
      },
      error: {
        main: colors.red,
        contrastText: "white"
      },
      success: {
        main: colors.green,
        contrastText: "white"
      }
    }
  });
  const [knownAuthState, setKnownAuthState] = useState(false);
  const [inviteVerification, setInviteVerification] = useState<InviteVerification | null>(null);
  const [inviteId, setInviteId] = useState<string | null>(null);
  const [user, setUser] = useState<PropayaUser | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setKnownAuthState(true);
      if (user == null) {
        setUser(null);
      }
      else {
        BackendAPI.GetUser(user).then((propayaUser) => setUser(propayaUser))
      }
      console.log("Firebase user: ");
      console.log(user);
      //check user http
    });
    return unsubscribe;
  }, []);

  return (
    knownAuthState ?
      <ThemeProvider theme={theme} >
        <BrowserRouter>
          <UserContext.Provider value={{ user, setUser }}>
            <Routes>
              <Route path="/" element={
                <AuthValidator>
                  <MobileValidator>
                    <Page />
                  </MobileValidator>
                </AuthValidator>
              }>
                <Route index element={user !== null && user.organization_id !== null ?
                  <LeaseAnalysisView /> :
                  <Box margin={theme.spacing(4)}>
                    <Typography variant='h5'>This user is not currently associated with an organization.</Typography>
                    <Button variant='contained' onClick={() => auth.signOut().then(() => window.location.reload())} >Sign out</Button>
                  </Box>
                } />
                {user !== null && user.role === UserRole.ADMIN && <Route path="organization" element={
                  <ManageOrganization />
                } />}
                <Route path="documents" element={
                  <Documents />
                } />
                <Route path="accountsettings" element={
                  <AccountSettings />
                } />
                <Route path="analysis-qa" element={
                  <LeaseAnalysisQAView />
                } />
              </Route>
              <Route path="login" element={
                <InviteContext.Provider value={{ inviteVerification, setInviteVerification, inviteId, setInviteId }}>
                  <SignIn />
                </InviteContext.Provider>
              } />
              <Route path="accept-invite" element={
                <InviteContext.Provider value={{ inviteVerification, setInviteVerification, inviteId, setInviteId }}>
                  <AcceptInvite />
                </InviteContext.Provider>
              } />

            </Routes>
          </UserContext.Provider>
        </BrowserRouter>
      </ThemeProvider >
      :
      <Loading />
  );
}

export default App;
