import { Link, Outlet, useLocation } from "react-router";
import { AppBar, Box, Drawer, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from "@mui/material";
import { AccountCircle, BugReport, CalendarToday, HomeWork, InsertChartOutlined } from "@mui/icons-material";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { NavDrawerHeader } from "./NavDrawerHeader";
import { NavDrawerFooter } from "./NavDrawerFooter";
import colors from "../lib/colors";
import { createContext, useContext, useEffect, useState } from "react";
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from "./Page";
import { UserRole } from "../lib/enums";
import { UserContext } from "../App";

interface SidebarProps {
    navDrawerCollapsed: boolean
    navDrawerWidth: number
    setNavDrawerCollapsed: (collapsed: boolean) => void
    setNavDrawerWidth: (width: number) => void
}

export function Sidebar({ navDrawerCollapsed, navDrawerWidth, setNavDrawerCollapsed, setNavDrawerWidth }: SidebarProps) {
    const theme = useTheme();
    const userContext = useContext(UserContext);
    let currentPath: string = useLocation().pathname.split("/")[1];


    useEffect(() => {
        const query = window.matchMedia("(max-width: 1442px)");

        function handleQueryChange(queryEvent: MediaQueryListEvent) {
            /* The matches property will be true if the window width is below the mobile size. */
            setNavDrawerCollapsed(queryEvent.matches);
            setNavDrawerWidth(queryEvent.matches ? COLLAPSED_WIDTH : EXPANDED_WIDTH);
        }

        query.addEventListener('change', handleQueryChange);

        return () => {
            query.removeEventListener('change', handleQueryChange);
        };
    }, []);

    return (
        <Drawer sx={{ width: navDrawerWidth }} variant="permanent" anchor="left" PaperProps={{ sx: { backgroundColor: colors.SchemesSurfaceContainerLow, borderRight: "0" } }} >
            <Box borderRadius={theme.spacing(4)} margin={theme.spacing(2)} sx={{ width: `calc(${navDrawerWidth}px - ${theme.spacing(2)})`, padding: theme.spacing(2), paddingTop: theme.spacing(4), height: "100%", display: "flex", flexDirection: "column", backgroundColor: "white" }} >
                <NavDrawerHeader navDrawerCollapsed={navDrawerCollapsed} />
                <Box flex="1">
                    <List>
                        <ListItem color="inherit" component={Link} to={"/"} >
                            <ListItemButton selected={currentPath == ""} color="inherit">
                                <ListItemIcon>
                                    <InsertChartOutlined />
                                </ListItemIcon>
                                {!navDrawerCollapsed &&
                                    <ListItemText slotProps={{
                                        primary: {
                                            sx: {
                                                fontWeight: currentPath == "" ? "bold" : "regular",
                                                fontSize: 18
                                            }
                                        }
                                    }} color="inherit"
                                        primary="Lease Analysis" />
                                }
                            </ListItemButton>
                        </ListItem>
                        <Box alignSelf="stretch">
                            <Divider variant="middle" />
                        </Box>
                        {userContext.user !== null && userContext.user.role === UserRole.ADMIN && <ListItem color="inherit" component={Link} to={"/organization/"}>
                            <ListItemButton selected={currentPath == "organization"}>
                                <ListItemIcon>
                                    <HomeWork sx={{ margin: 0 }} />
                                </ListItemIcon>
                                {!navDrawerCollapsed &&
                                    <ListItemText slotProps={{
                                        primary: {
                                            sx: {
                                                fontWeight: currentPath == "organization" ? "bold" : "regular",
                                                fontSize: 18
                                            }
                                        }
                                    }} color="inherit"
                                        primary="My Organization" />
                                }
                            </ListItemButton>
                        </ListItem>}
                        <ListItem component={Link} to={"/documents/"}>
                            <ListItemButton selected={currentPath == "documents"}>
                                <ListItemIcon>
                                    <FolderSharedIcon />
                                </ListItemIcon>
                                {!navDrawerCollapsed &&
                                    <ListItemText slotProps={{
                                        primary: {
                                            sx: {
                                                fontWeight: currentPath == "documents" ? "bold" : "regular",
                                                fontSize: 18
                                            }
                                        }
                                    }} color="inherit"
                                        primary="Documents" />
                                }
                            </ListItemButton>
                        </ListItem>
                        <ListItem component={Link} to={"/analysis-qa"}>
                            <ListItemButton selected={currentPath == "analysis-qa"}>
                                <ListItemIcon>
                                    <BugReport />
                                </ListItemIcon>
                                {!navDrawerCollapsed &&

                                    <ListItemText
                                        slotProps={{
                                            primary: {
                                                sx: {
                                                    fontWeight: currentPath == "analysis-qa" ? "bold" : "regular",
                                                    fontSize: 18
                                                }
                                            }
                                        }}
                                        primary="QA Analysis" />
                                }
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <NavDrawerFooter navDrawerCollapsed={navDrawerCollapsed} setNavDrawerCollapsed={setNavDrawerCollapsed} setNavDrawerWidth={setNavDrawerWidth} />
            </Box>
        </Drawer>
    );
}
